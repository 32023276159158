import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styles from './blog-post.module.css'
import Helmet from 'react-helmet'

export default ({ data }) => {
  const post = data.markdownRemark
  const frontmatter = post.frontmatter
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title} - Remote Jobs Club: Work from Anywhere</title>
        <meta name="description" content={frontmatter.description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={frontmatter.description} />
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:site" content="@theremotejobs" />
        <meta name="twitter:creator" content="@sanjeevan84" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.description} />
        <meta property="og:url" content={'https://remotejobsclub.com' + frontmatter.slug} />
        <meta property="og:site_name" content="Sanj Ambalavanar" />
      </Helmet>
      <div className={styles.root}>
        <h1 className={styles.title}>{post.frontmatter.title}</h1>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      frontmatter: { slug: { eq: $slug }}
    ) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`
